import React from 'react';
import PropTypes from 'prop-types';

// Components
import StepConnector from './StepConnector';

const defaultConnector = <StepConnector />;

const Stepper = props => {
  const {
    children,
    connector: connectorProp = defaultConnector,
    activeStep = 0,
  } = props;

  const connector = React.isValidElement(connectorProp)
    ? React.cloneElement(connectorProp)
    : null;

  const childrenArray = React.Children.toArray(children);
  const steps = childrenArray.map((step, index) => {
    const state = {
      index,
      active: false,
      completed: false,
      disabled: false,
    };

    if (activeStep === index) {
      state.active = true;
    } else if (activeStep > index) {
      state.completed = true;
    } else if (activeStep < index) {
      state.disabled = true;
    }

    return React.cloneElement(step, {
      connector,
      last: index + 1 === childrenArray.length,
      ...state,
      ...step.props,
    });
  });
  /**
   *  Negative margin is for move up Container which has padding top 50
   *  Container is by design 35 px from stepper but on pages w/o stepper is 50
   */
  return <div className="flex items-start flex-row mt-45 -mb-15">{steps}</div>;
};

Stepper.propTypes = {
  activeStep: PropTypes.number,
  children: PropTypes.node.isRequired,
  connector: PropTypes.node.isRequired,
};

export default Stepper;
