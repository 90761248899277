import React from 'react';
import clsx from 'clsx';

const createConnector = (connectorProp, props, additionalProps) => {
  const connector = connectorProp
    ? React.cloneElement(connectorProp, {
        ...props,
        ...additionalProps,
      })
    : null;
  return connector;
};

const indexToStepMap = {
  0: 'first',
  1: 'second',
  2: 'third',
  3: 'fourth',
};

const Step = props => {
  const {
    children,
    active = false,
    completed = false,
    connector: connectorProp,
    index,
    last,
    alternativeLabel,
    route,
  } = props;
  const defaultConnectorProps = {
    alternativeLabel,
    index,
    active,
    completed,
  };
  const newChildren = (
    <div
      className={clsx('flex h-20 items-center relative', {
        [`${indexToStepMap[index]}-step`]: !active,
        [`${indexToStepMap[index]}-step-active`]: active,
      })}
    >
      {connectorProp && index === 0
        ? createConnector(connectorProp, defaultConnectorProps, {
            className: active
              ? 'step-connector-first-active'
              : 'step-connector-first',
          })
        : null}

      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child, {
          active,
          completed,
          last,
          icon: index + 1,
          ...child.props,
        });
      })}
      {connectorProp && index >= 0 && !last
        ? createConnector(connectorProp, defaultConnectorProps, { route })
        : null}
      {connectorProp && last
        ? createConnector(connectorProp, defaultConnectorProps, {
            className: active
              ? 'step-connector-last-active'
              : 'step-connector-last',
          })
        : null}
    </div>
  );

  return newChildren;
};

export default Step;
