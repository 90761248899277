import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// Components
import Stepper from './Stepper';
import Step from './Step';
import StepLabel from './StepLabel';
import StepConnector, { useGetActiveRoute } from './StepConnector';

// Hooks
import { useLocalStorageState } from '../../hooks/useLocalStorageState';

// Translations
import messages from '../../messages/common';
import translations from '../../translations/main.json';
import routes from '../../messages/routes';
import { LoginSource } from '../../hooks/useAuthenticate';
import { translateRoute } from '../../utils/routes';

const steps = [
  messages.stepFlightInfo,
  messages.stepPersonalInfo,
  messages.stepAuthorisation,
  messages.stepFinish,
];
const routesToStepMap = {
  '/': 0,
  [`${translations['cc4.seo.route.itinerary']}`]: 0,
  [`${translations['cc4.seo.route.connecting-flights']}`]: 0,
  [`${translations['cc4.seo.route.alternativeFlight']}`]: 0,
  [`${translations['cc4.seo.route.alternative-flights']}`]: 0,
  [`${translations['cc4.seo.route.check-compensation']}`]: 0,
  [`${translations['cc4.seo.route.flight-compensation']}`]: 0,
  [`${translations['cc4.seo.route.congratulations-basepath']}`]: 1,
  [`${translations['cc4.seo.route.congratulations-basepath']}/${translations['cc4.seo.route.permission']}`]: 1,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.questionnaire']}`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.passengers-list']}`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.mainbooker-info']}`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.passenger']}`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.passenger']}/:passengerId`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.passenger']}/:passengerId/${translations['cc4.seo.route.legal-guardian-info']}`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.mail-upload-flight-documents']}`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.upload-id']}`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.upload-documents']}`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.claim-amount']}`]: 2,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.booking-reference']}`]: 2,
  [`${translations['cc4.seo.route.login']}`]: 0,
  [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.login']}`]: 3,
};

const stepsToRoutesMap = {
  // group
  0: {
    // route
    '/': 25, // percentage
    [`${translations['cc4.seo.route.itinerary']}`]: 50,
    [`${translations['cc4.seo.route.connecting-flights']}`]: 50,
    [`${translations['cc4.seo.route.alternativeFlight']}`]: 75,
    [`${translations['cc4.seo.route.alternative-flights']}`]: 75,
    [`${translations['cc4.seo.route.check-compensation']}`]: 75,
    [`${translations['cc4.seo.route.flight-compensation']}`]: 75,
    [`${translations['cc4.seo.route.login']}`]: 75,
  },
  1: {
    [`${translations['cc4.seo.route.congratulations-basepath']}`]: 50,
    [`${translations['cc4.seo.route.congratulations-basepath']}/${translations['cc4.seo.route.permission']}`]: 50,
  },
  2: {
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.questionnaire']}`]: 25,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.passengers-list']}`]: 50,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.mainbooker-info']}`]: 50,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.passenger']}`]: 50,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.passenger']}/:passengerId`]: 50,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.passenger']}/:passengerId/${translations['cc4.seo.route.legal-guardian-info']}`]: 50,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.mail-upload-flight-documents']}`]: 75,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.upload-id']}`]: 75,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.upload-documents']}`]: 75,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.claim-amount']}`]: 100,
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.booking-reference']}`]: 100,
  },
  3: {
    [`${translations['cc4.seo.route.congratulations-basepath']}/:claimNumber/${translations['cc4.seo.route.login']}`]: 100,
  },
};

function stripTrailingSlash(str) {
  if (!str) return;
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
}

const CalculatorStepper = props => {
  const { location } = props;
  const [activeStep, setActiveStep] = useState(null);
  const { formatMessage } = useIntl();
  const [shouldAuthenticate] = useLocalStorageState('shouldAuthenticate');
  const activeRoute = Object.keys(routesToStepMap).find(useGetActiveRoute);

  useEffect(() => {
    let path = stripTrailingSlash(location?.pathname);
    const newStep = routesToStepMap[activeRoute];
    if (path === `/${translateRoute(routes.login)}`) {
      if (shouldAuthenticate) setActiveStep(1);
      if (!shouldAuthenticate && location?.state?.source === LoginSource.Funnel)
        setActiveStep(3);
      if (
        !shouldAuthenticate &&
        location?.state?.source !== LoginSource.Funnel
      ) {
        setActiveStep(null);
      }
    } else {
      setActiveStep(newStep);
    }
    // eslint-disable-next-line
  }, [activeRoute]);

  if (activeStep === null) {
    return null;
  }

  return (
    <div className="hidden sm:block container w-full mx-auto mdd:max-w-none ">
      <Stepper
        activeStep={activeStep}
        connector={<StepConnector location={location} />}
      >
        {Object.entries(stepsToRoutesMap).map(([key, route]) => (
          <Step key={key} route={route}>
            <StepLabel>{formatMessage(steps[key])}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

CalculatorStepper.propTypes = {
  location: PropTypes.object,
};

export default CalculatorStepper;
