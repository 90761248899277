import React from 'react';
import PropTypes from 'prop-types';
import { Location, useLocation } from '@reach/router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Layout from './layout';
import { StateProvider } from '../services/GlobalStore/GlobalStore';
import TranslatedRouter from './TranslatedRouter';
import CalculatorStepper from './Stepper';
import translatable from '../utils/propTypes/translatable';
import LayoutHeader from './LayoutHeader';

const PageTransition = ({
  children,
  showStepper = true,
  hideMenu,
  ...props
}) => {
  const { token } = useLocation();
  return (
    <Layout showStepper={showStepper}>
      <StateProvider>
        <Location>
          {({ location }) => (
            <>
              <LayoutHeader
                showStepper={showStepper}
                location={location}
                hideMenu={hideMenu}
              />
              {showStepper && !token ? (
                <CalculatorStepper location={location} />
              ) : null}
              <TransitionGroup className="w-full container mx-auto mdd:max-w-none">
                <CSSTransition
                  key={location.key}
                  timeout={300}
                  classNames="pageSlider"
                >
                  <TranslatedRouter
                    location={location}
                    className="w-full mx-auto"
                    {...props}
                  >
                    {children}
                  </TranslatedRouter>
                </CSSTransition>
              </TransitionGroup>
            </>
          )}
        </Location>
      </StateProvider>
    </Layout>
  );
};

PageTransition.propTypes = {
  basepath: PropTypes.oneOfType([PropTypes.string, translatable]),
  children: PropTypes.any,
  hideMenu: PropTypes.bool,
  showStepper: PropTypes.bool,
};

export default PageTransition;
