import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { useLocation, useMatch } from '@reach/router';
import { LoginSource } from '../../hooks/useAuthenticate';

const indexToStepMap = {
  0: 'second',
  1: 'third',
  2: 'fourth',
};

export const useGetActiveRoute = route => {
  const activeRoute = useMatch(route);
  return activeRoute;
};

const StepConnector = props => {
  const { state } = useLocation();
  const { active, completed, className, route, index } = props;
  const activeRoute =
    !isEmpty(route) && Object.keys(route).find(useGetActiveRoute);
  // Get percentage from routes map
  let linePercentage = route && activeRoute ? route[activeRoute] : null;
  /**
   * Login is defined as 75 percent of 0 step, because we want to show when
   * user has to log in with existing account.
   * But if it's logins screen after whole claim process finished we show 100%
   */
  if (activeRoute === 'login' && state?.source === LoginSource.Funnel)
    linePercentage = 100;
  return (
    <div
      className={clsx(className, {
        [`step-connector-${indexToStepMap[index]}`]: !className && !active,
        [`step-connector-${indexToStepMap[index]}-active`]:
          !className && active,
      })}
    >
      <span
        className={clsx('step-connector-line block border-t-2 rounded-sm', {
          ['border-gray-700']: !active && !completed,
          ['border-primary-400']: active || completed,
          ['step-connector-line-0']: linePercentage === 0,
          ['step-connector-line-25']: linePercentage === 25,
          ['step-connector-line-50']: linePercentage === 50,
          ['step-connector-line-75']: linePercentage === 75,
          ['step-connector-line-100']: linePercentage === 100,
        })}
      />
    </div>
  );
};

StepConnector.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  index: PropTypes.number,
  route: PropTypes.object,
};

export default StepConnector;
