import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Icons
import IconActive from '../../assets/icons/circle-active.inline.svg';
import IconDone from '../../assets/icons/circle-done.inline.svg';
import IconGrey from '../../assets/icons/circle-grey.inline.svg';

const StepLabel = props => {
  const { children, active = false, completed = false, icon } = props;
  const IconComponent = completed ? IconDone : active ? IconActive : IconGrey;
  const size = active ? 40 : 34;

  return (
    <span
      className="flex items-center flex-col"
      style={{
        width: `${size}px`,
      }}
    >
      <span className="absolute w-full -mt-25">
        {children ? (
          <span
            className={clsx(
              'block text-center text-sm font-bold leading-normal mb-5 font-pt-sans',
              {
                ['text-gray-500']: !active || completed,
                ['text-h1']: active,
              }
            )}
          >
            {children}
          </span>
        ) : null}
      </span>
      {icon || IconComponent ? (
        <span className={clsx('pr-0 flex flex-shrink-0')}>
          <IconComponent
            className={clsx({
              'stroke-circle-primary': active,
              'fill-circle-primary': completed,
            })}
          />
        </span>
      ) : null}
    </span>
  );
};

StepLabel.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  completed: PropTypes.bool,
  icon: PropTypes.number,
};

export default StepLabel;
